import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DesertService {
  private setAzimuth = new BehaviorSubject<number>(0.01);
  azimuth = this.setAzimuth.asObservable();
  increment = 0.04

  constructor() { }

  moveSun(direction) {

    let azimuth;

    let currentAzimuth = Math.round(this.setAzimuth.value * 1000) / 1000;
    currentAzimuth === 1 ? currentAzimuth = 0 : null;

    switch (direction) {
      case 'up':
        azimuth = Math.round((currentAzimuth + this.increment) * 1000) / 1000;
        break;
      case 'down':
        azimuth = Math.round((currentAzimuth - this.increment) * 1000) / 1000;
        break;
    }

    this.setAzimuth.next(azimuth % 2);

  }

}
