import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DissolveService {

  tileLifeInMs = 1000 * 60 * 60 * 24 * 365 * 5;

  constructor() { }

  calculateAlpha(date) {
    let dateCreated = new Date(parseInt(date, 10));
    let terminalDate = dateCreated.getTime() + this.tileLifeInMs;
    let now = new Date(Date.now());
    let alpha = terminalDate - now.getTime();
    return (alpha / this.tileLifeInMs).toFixed(1);
  }

}
