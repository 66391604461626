import { Component, OnInit, AfterViewInit } from '@angular/core';
import {MatChipInputEvent} from '@angular/material/chips';
import { SortablejsModule } from 'ngx-sortablejs';
import { TileService } from '../../services/tile.service';

@Component({
  selector: 'app-emoji-select',
  templateUrl: './emoji-select.component.html',
  styleUrls: ['./emoji-select.component.scss']
})
export class EmojiSelectComponent implements OnInit {


  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  emojis = [];

  options;

  constructor(private tileService: TileService) {
    this.options = {
      onUpdate: (event: any) => {
        let newPalette = this.emojis.join(',');
        this.tileService.setPalette(newPalette);
      }
    };
  }

  ngOnInit() {
    console.log(localStorage.getItem('emoji-mart.last'));
    this.tileService.getPalette.subscribe((palette) => {
      if ( palette !== '' ) {
        this.emojis = palette.split(',');
      }
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.emojis.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    let newPalette = this.emojis.join(',');
    this.tileService.setPalette(newPalette);
  }

  remove(emoji) {
    const index = this.emojis.indexOf(emoji);

    if (index >= 0) {
      this.emojis.splice(index, 1);
    }

    let newPalette = this.emojis.join(',');
    this.tileService.setPalette(newPalette);
  }

  getRecent(recent) {
    console.log(recent);
  }

  addEmoji(event) {
    // console.log(picker);
    this.add({
      value: event.emoji.native,
      input: null
    });
  }

}
