import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import * as THREE from 'three';
import { Sky } from '../assets/scripts/sky';
import { OrbitControls } from '../assets/scripts/OrbitControls';
import { DesertService } from './services/desert.service';
import { CameraPositionService } from './services/camera.service';
import { DialogService } from './services/dialog.service';
import { DialogComponent } from './dialog/dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  @ViewChild('rendererContainer', { static: true }) rendererContainer: ElementRef;

  renderer = new THREE.WebGLRenderer();
  controls = null;
  scene = null;
  camera = null;

  menuActive = false;

  centerPosition = { lat: 1000, lng: 1000 };

  sky;
  sunSphere;

  effectController = {
    turbidity: 20,
    rayleigh: 1.249,
    mieCoefficient: 0,
    mieDirectionalG: 0.399,
    luminance: .7,
    inclination: 0,
    azimuth: 0.02,
    sun: !true
  };

  constructor(
    private desertService: DesertService,
    private matDialog: MatDialog,
    private dialogService: DialogService,
    private cameraPositionService: CameraPositionService
  ) {

    this.cameraPositionService.rotate.subscribe((rotate) =>  {
      if ( this.controls ) {
        rotate ? this.controls.enableRotate = true : this.controls.enableRotate = false;
        this.controls.update();
      }
    });

    this.dialogService.activeDialog.subscribe((dialog) =>  {
      this.controls ? this.openDialog(dialog) : null;
    });

    // Get azimuth from desert service
    this.desertService.azimuth.subscribe((azimuth) => {

      if (this.sunSphere) {

        let current = this.effectController.azimuth * 100;

        current === 100 ? current = 0 : current = current;

        let target = azimuth * 100;

        let sun = {
          azimuth: current,
        };

        anime({
          targets: sun,
          azimuth: target,
          easing: 'easeOutQuad',
          update: () => {
            this.effectController.azimuth = sun.azimuth / 100;
            this.moveSun();
          }
        });

      }
    });

    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 5, 2000000 );

    this.camera.position.set( 0, -200, 400 );

    this.initSky();

    // Camera controls
    this.controls = new OrbitControls( this.camera );
    let animate = function() {
      this.render();
    }
    this.controls.addEventListener( 'change', animate.bind(this) );
    this.controls.rotateSpeed = - .1;
    this.controls.enableZoom = false;
    this.controls.enablePan = false;
    this.controls.enableRotate = true;

    let onWindowResize = function() {
			this.camera.aspect = window.innerWidth / window.innerHeight;
			this.camera.updateProjectionMatrix();
			this.renderer.setSize( window.innerWidth, window.innerHeight );
			this.animate();
		}
    window.addEventListener( 'resize', onWindowResize.bind(this), false );
  }

  ngAfterViewInit() {
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.animate();
  }

  openDialog(dialog) {
    this.controls.enabled = false;

    const dialogConfig = new MatDialogConfig();

    this.matDialog.open(DialogComponent, {
      data: dialog
    })
      .afterClosed().subscribe((result) => {
        this.controls.enabled = true;
      });
  }

  moveSun() {
    let distance = 700000;
    let theta = Math.PI * ( this.effectController.inclination - 0.5 );
    let phi = 2 * Math.PI * ( this.effectController.azimuth - 0.5 );
    this.sunSphere.position.x = distance * Math.cos( phi );
    this.sunSphere.position.y = distance * Math.sin( phi ) * Math.sin( theta );
    this.sunSphere.position.z = distance * Math.sin( phi ) * Math.cos( theta );
    this.sky.material.uniforms[ "sunPosition" ].value.copy( this.sunSphere.position );
    this.render();
  }

  initSky() {
    this.sky = new Sky();
    this.sky.scale.setScalar( 450000 );
    this.scene.add( this.sky );

    this.sunSphere = new THREE.Mesh(
      new THREE.SphereBufferGeometry( 100, 32, 8 ),
      new THREE.MeshBasicMaterial( { color: 0xffffff } )
    );
    this.sunSphere.position.y = -70000;
    this.sunSphere.visible = false;
    this.scene.add( this.sunSphere );

    this.sky.material.uniforms[ 'turbidity' ].value = this.effectController.turbidity;
    this.sky.material.uniforms[ 'rayleigh' ].value = this.effectController.rayleigh;
    this.sky.material.uniforms[ 'luminance' ].value = this.effectController.luminance;
    this.sky.material.uniforms[ 'mieCoefficient' ].value = this.effectController.mieCoefficient;
    this.sky.material.uniforms[ 'mieDirectionalG' ].value = this.effectController.mieDirectionalG;

    this.sunSphere.visible = this.effectController.sun;
    this.effectController.azimuth = 0.02 % 2;

    let distance = 700000;
    let theta = Math.PI * ( this.effectController.inclination - 0.5 );
    let phi = 2 * Math.PI * ( this.effectController.azimuth - 0.5 );
    this.sunSphere.position.x = distance * Math.cos( phi );
    this.sunSphere.position.y = distance * Math.sin( phi ) * Math.sin( theta );
    this.sunSphere.position.z = distance * Math.sin( phi ) * Math.cos( theta );

    this.sky.material.uniforms[ "sunPosition" ].value.copy( this.sunSphere.position );

    this.render();
  }

  animate() {
    window.requestAnimationFrame(() => {
      this.animate();
    });
    this.render();
  }

  render() {
    this.renderer.render( this.scene, this.camera );
  }

}
