import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CameraPositionService {
  private position = new BehaviorSubject({ lat: 0, lng: 0 });
  private depth = new BehaviorSubject(4);
  currentPosition = this.position.asObservable();
  currentDepth = this.depth.asObservable();

  private rotateToggle = new BehaviorSubject(false);
  rotate = this.rotateToggle.asObservable();

  constructor() { }

  updatePosition(cameraPosition) {
    this.position.next(cameraPosition);
  }

  updateDepth(zoomLevel) {
    this.depth.next(zoomLevel);
  }

  toggleRotate() {
    this.rotateToggle.next(!this.rotateToggle.value);
  }

}
