import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DialogService {
  private dialog = new BehaviorSubject('');
  activeDialog = this.dialog.asObservable();

  constructor() { }

  open(dialog) {
    this.dialog.next(dialog);
  }

}
