import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';


@Injectable({
  providedIn: 'root'
})
export class LocalBoardService {

  board: AngularFireObject<any>;

  private session = {};
  private added = new BehaviorSubject<{}>({});
  private changed = new BehaviorSubject<{}>({});
  private removed = new BehaviorSubject<{}>({});
  on_added = this.added.asObservable();
  on_changed = this.changed.asObservable();
  on_removed = this.removed.asObservable();

  constructor(private database: AngularFireDatabase) { }

  child(coords, tile) {

    const tileParams = {
      key: coords,
      val: function() {
        return tile;
      }
    };

    if (this.session.hasOwnProperty(coords)) {
      this.session[coords] = tile;
      this.changed.next(tileParams);
    } else {
      this.session[coords] = tile;
      this.added.next(tileParams);
    }

  }


}
