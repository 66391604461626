import { Component, OnInit, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as firebase from 'firebase/app';
import * as L from 'leaflet';
import '../../assets/scripts/leaflet-canvas-layer/dist/leaflet-canvas-layer.js';
import '../../assets/scripts/leaflet/leaflet.canvas-markers.js';
import '../../assets/scripts/Leaflet.MapCenterCoord-v0.7/dist/L.Control.MapCenterCoord.min.js';
import '../../assets/scripts/Leaflet.MapCenterCoord-v0.7/dist/Leaflet.Control.Custom.js';
import { CameraPositionService } from '../services/camera.service';
import { TileService } from '../services/tile.service';
import { DialogService } from '../services/dialog.service';
import { DesertService } from '../services/desert.service';
import { LocalBoardService } from '../services/board.service';
import { DissolveService } from '../services/dissolve.service';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  SPACE = 32
}

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})

export class BoardComponent implements OnInit, OnInit, OnDestroy, AfterViewInit {
  private element: HTMLImageElement;

  // firebase
  board: AngularFireObject<any>;
  boardName = 'public';
  boardRef;

  // router
  urlCoordinates = this.route.snapshot.paramMap.get('coords');

  // leaflet
  map;
  ciLayer;
  tile;
  cursorPosition;

  // drawing tiles
  currentZoom = 0;
  font = [ '12px NotoSans:0:14', '30px NotoSans:2:28', '60px NotoSans:0:56', '120px NotoSans:0:108' ]; // for drawing tiles
  tileSize = 15; // size of tile relative to leaflet coordinates
  icons = { 0: '', 1: '', 2: '', 3: '' }; // temporary storage of leaflet icon (at each size) to be made into marker
  imgData = {0: {}, 1: {}, 2: {}, 3: {}}; // stores discrete tile img data to save time redrawing
  markers = { 0: [], 1: [], 2: [], 3: [] }; // stores the leaflet markers
  markerCounter = { 0: 0, 1: 0, 2: 0, 3: 0 }; // stores the most recent marker's index (in this.markers)
  coordsToIndex = { 0: {}, 1: {}, 2: {}, 3: {} }; // stores marker's coords (key) and index in this.markers (value)
  pathArray = []; // stores cursor path (array of coordinates) for Bresenham's line algorithm

  // ui
  space = false;
  tool;
  dragging;
  snack;

  snackCounter = 0;

  constructor(
    private database: AngularFireDatabase,
    private cameraPositionService: CameraPositionService,
    private localBoardService: LocalBoardService,
    private tileService: TileService,
    private dialogService: DialogService,
    private desertService: DesertService,
    private dissolveService: DissolveService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.snack = this.snackBar;
  }

  @HostListener('window:keyup', ['$event'])
  keyUp(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.SPACE) {

      // change cursor to crosshair on #map
      document.getElementById('map').style.cursor = 'crosshair';

      this.enableMoveTools();
      this.space = false;
      this.tileService.update();
      this.pathArray = [];
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyDown(event: KeyboardEvent) {
    this.space === false ? this.tileService.update() : null;
    if ( event.keyCode === KEY_CODE.SPACE && this.tile.split(':')[0] !== '' ) {
      this.space = true;
      const y = this.cursorPosition.lat;
      const x = this.cursorPosition.lng;
      this.boardRef.child(x + ':' + y).set(this.tile);
    }
  }

  //add host listener for click and drag
  @HostListener('window:mousemove', ['$event'])
  mouseMove(event: MouseEvent) {
    // if mousemove and heldown, change cursor to grabbing on #map
    if (event.buttons === 1) {
      document.getElementById('map').style.cursor = 'grabbing';
    }
  }
  



  ngOnInit() {
    //set map opacity to 0
    document.getElementById('map').style.opacity = '0';

    // show loading indicator until board is loaded
    this.snack.open('Loading...', '', {
      duration: 0,
      panelClass: 'loading'
    });

    


    try {
      this.tileService.init();
    } catch(e) {
      console.log(e);
    }
    this.tileService.params.subscribe((tile) => {
      this.tile = tile;
      if ( tile.split(':')[0] !== '' ) {
        // this.snack.dismiss();
      }
    });

    this.boardRef = firebase.database().ref(this.boardName);

    // Initialize leaflet map
    this.map = L.map('map', {
      crs: L.CRS.Simple,
      keyboard: true,
      minZoom: 0,
      maxZoom: 2,
      attributionControl: false,
      renderer: L.canvas(),
      preferCanvas: true,
      inertia: false,
      animate: false,
      zoomAnimation: false,
      doubleClickZoom: false,
      scrollWheelZoom: false,
      center: [0,0],
      zoomControl: false
    })
    .setView([0, 0], this.currentZoom);



    // Add coordinates display
    L.control.mapCenterCoord({
      icon: false,
      onMove: false,
      position: 'topright',
    }).addTo(this.map);

    // Add canvas layer
    this.ciLayer = L.canvasIconLayer({
      inertia: false,
      preferCanvas: true,
      animate: false,
      crs: L.CRS.Simple
    }).addTo(this.map);


    // Add menu buttons
    L.Control.About = L.Control.extend({
      onAdd: (map) => {
        let active = false;

        // creates div for control button
        let div = L.DomUtil.create('div');
        // creates div for control button
        div.style.backgroundColor = 'rgba(255,255,255,1)';
        div.style.borderRadius = '10px';
        div.style.width = '80px';
        div.style.height = '80px';
        div.style.boxShadow = '0px 0px 10px 0px rgba(0,0,0,0.75)';
        div.style.margin = '10px';



        // appends image of questionmark to div
        let img = L.DomUtil.create('img', '', div);
// create image from canvas with sos emoji in middle
        let canvasSOS = document.createElement('canvas');
        canvasSOS.width = 80;
        canvasSOS.height = 80;
        let ctx = canvasSOS.getContext('2d');
        ctx.fillStyle = 'rgba(255,255,255,1)';
        // ctx.fillRect(0,0,80,80);
        ctx.font = '70px Arial';
        ctx.fillStyle = 'rgba(0,0,0,1)';
        ctx.fillText('❓', 5, 65);
        img.src = canvasSOS.toDataURL();
        img.style.height = '80px';
               
        
        img.style.width = '80px';
        img.style.cursor = 'pointer';

        L.DomEvent.on(img, 'click', (event) => {
          L.DomEvent.stopPropagation(event);

          this.dialogService.open('about');
        });
        return div;
      },
      onRemove: function(map) {
        L.DomEvent.off();
      }
    }).bind(this);
    L.control.about = function(opts) {
      return new L.Control.About(opts);
    }


    L.Control.Palette = L.Control.extend({

      onAdd: (map) => {
        let active = false;

        // creates div for control button
        let div = L.DomUtil.create('div', 'leaflet-control-div');
        div.style.backgroundColor = 'rgba(255,255,255,1)';
        div.style.borderRadius = '10px';
        div.style.width = '80px';
        div.style.height = '80px';
        div.style.boxShadow = '0px 0px 10px 0px rgba(0,0,0,0.75)';
        

        div.style.margin = '10px';

        // appends image of palette to div
        let img = L.DomUtil.create('img', '', div);
        // create image from canvas with palette emoji in middle
        let canvasPalette = document.createElement('canvas');
        canvasPalette.width = 80;
        canvasPalette.height = 80;
        let ctx = canvasPalette.getContext('2d');
        ctx.fillStyle = 'rgba(255,255,255,1)';
        // ctx.fillRect(0,0,80,80);
        ctx.font = '70px Arial';
        ctx.fillStyle = 'rgba(0,0,0,1)';
        ctx.fillText('🎨', 5, 65);
        img.src = canvasPalette.toDataURL();
        
        img.style.height = '80px';
        img.style.width = '80px';
        img.style.cursor = 'pointer';


        L.DomEvent.on(window, 'keydown', (event) => {
          //  remind user to select an emoji
          if ( event.keyCode === KEY_CODE.SPACE && this.tile.split(':')[0] === '' ) {
            // appends reminder div to control div
            let reminder = L.DomUtil.create('div', 'palette-reminder fadeIn', div);
            let tooltipTemplate = '<div class="test">Click here to select an emoji</div>';
            let tooltipContent = L.Util.template(tooltipTemplate);
            L.DomUtil.get(reminder).innerHTML = tooltipContent;
            // css fadeout - doing this in a hurry, theres got to be a better way
            setTimeout(() => {
              L.DomUtil.removeClass(reminder, 'fadeIn');
              L.DomUtil.addClass(reminder, 'fadeOut');
              setTimeout(() => {
                L.DomUtil.remove(reminder);
              }, 500);
            }, 2000);
          } else {
            L.DomEvent.off(window, 'keydown', this);
          };

          L.DomEvent.stopPropagation(event);
        });

        L.DomEvent.on(img, 'click', (event) => {
          L.DomEvent.stopPropagation(event);
          //define paletteControl
;
          

          this.dialogService.open('emoji-select');

        });

        return div;
      },

      onRemove: function(map) {
        L.DomEvent.off();
      }
    }).bind(this);

    L.control.palette = function(opts) {
      return new L.Control.Palette(opts);
    }

    let paletteControl = L.control.palette({ position: 'topleft' }).addTo(this.map);
    let aboutControl = L.control.about({ position: 'bottomright' }).addTo(this.map);
    //add a setTImeout for 3 seconds
    setTimeout(() => {
      //make map opacity 1
      this.map.getContainer().style.opacity = 1;
      
      //dismiss snackbar
      this.snackBar.dismiss();


      const snackMessages = [
        'Click & drag to move around the map.',
        'Use the mouse wheel to zoom in and out.',
        'Click the pallette icon in the upper left corner to select your desired emojis. ↖️',
        'Hold down the space bar and move the cursor to paint the selected emojis onto the map.',
        'Click the ? emoji in the bottom left to see these directions again. ➡️',
        'Emojis dissolve in 5 years.'
        
      ];
      
      //cycle through snackMessages when closing snackbars
      let i = 0;
      const that = this;
      
      let snackBarCycle = function() {
        //remove wiggle class from palette and about icons
        paletteControl.getContainer().classList.remove('wiggle');
        aboutControl.getContainer().classList.remove('wiggle');

        if ( i === 2 ) {
          paletteControl.getContainer().classList.add('wiggle');
        } else if (i === 4) {
          aboutControl.getContainer().classList.add('wiggle');
        }

        that.snackBar.open(snackMessages[i], 'Got it')
          .afterDismissed().subscribe(() => {
            i++;
            if (i < snackMessages.length) {
              snackBarCycle();
            }
          });
      }
      snackBarCycle();

    }, 3000);

    //remove class wiggle from paletteControl when emoji-select dialog is opened
    this.dialogService.activeDialog.subscribe((e) => {
      if (e === 'emoji-select') {
        paletteControl.getContainer().classList.remove('wiggle');
      } else {
        aboutControl.getContainer().classList.remove('wiggle');
      }
    });

  
    

    this.map.on('mousemove', function(e) {
      this.cursorPosition = {
        lat: ((Math.ceil(e.latlng.lat / this.tileSize))),
        lng: ((Math.floor(e.latlng.lng / this.tileSize)))
      };
      if ( this.space ) {
        this.disableMoveTools();
        const y = ((Math.ceil(e.latlng.lat / this.tileSize)));
        const x = ((Math.floor(e.latlng.lng / this.tileSize)));
        this.pathArray.unshift([x, y]);
        if (this.pathArray.length > 1) {
          for (let i = 0; i < 2; i++ ) {
            if (i > 0) {
              const x0 = this.pathArray[0][0];
              const y0 = this.pathArray[0][1];
              const x1 = this.pathArray[i][0];
              const y1 = this.pathArray[i][1];
              this.bresenhamsLine(x0, y0, x1, y1);
            }
          }
        }
      }
    }.bind(this));

    this.map.on('zoomend', function(e) {
      this.currentZoom = this.map.getZoom();
      this.resize();
    }.bind(this));

  }


  ngOnDestroy() {
    // this.routeSubscription.unsubscribe();
  }


  ngAfterViewInit() {
    this.tileService.update();

    this.boardRef = firebase.database().ref(this.boardName);

    
    

  
    
    
    

    // Tile methods
    const drawTile = (snapshot, type) => {

      const tile = snapshot.val().split(':');
      const coords = snapshot.key.split(':');

      // Extracts opacity and emoji
      let color = 'rgba(255,255,255,' + this.dissolveService.calculateAlpha(tile[1]) + ')';
      let emoji = tile[0].toString();

      // Sets distance between tiles on map
      const x = coords[0] * this.tileSize;
      const y = coords[1] * this.tileSize;

      // References hidden canvas for drawing icons to be made into markers
      let canvas = document.getElementById('canvas') as HTMLCanvasElement;
      let ctx = canvas.getContext('2d');

      // Creates leaflet markers for each tile at each size
      for (let size = 0; size < Object.keys(this.markers).length; size++) {

        // For calculating dimensions
        const multiplier = this.tileSize ^ (size + 1);

        // Pulls stored imgData if tile has already been drawn (saves time redrawing)
        let iconUrl;
        if (this.imgData[size][emoji] && this.imgData[size][emoji].color === color) {
          iconUrl = this.imgData[size][emoji].data;

        } else {
          // Prepares canvas for drawing
          canvas.width = this.tileSize * multiplier * 4;
          canvas.height = this.tileSize * multiplier;

          // Clears the canvas of previous tile
          ctx.clearRect(0, 0, (this.tileSize * multiplier) * 4, this.tileSize * multiplier);

          ctx.fillStyle = color;
          let font = this.font[size].split(':')[0];
          ctx.font = font;
          ctx.fillText(emoji, parseInt(this.font[size].split(':')[1], 10), parseInt(this.font[size].split(':')[2], 10));

          // Stores icon
          this.icons[size] = canvas.toDataURL();

          // Stores img data
          this.imgData[size][emoji] = {
            data: this.icons[size],
            color: color
          };

          iconUrl = this.icons[size];
        }

        // Generates leaflet icon from latest tile
        const icon = new L.icon({
          iconUrl: iconUrl,
          iconRetinaUrl: iconUrl,
          iconSize: [(this.tileSize * multiplier) * 4, this.tileSize * multiplier],
          iconAnchor: [0, 0]
        });

        // Uses leaflet icon to make leaflet marker
        const marker = L.marker([y, x], {
          icon: icon,
        });

        // Stores index of most recent marker in this.markers to be used during clearTile
        this.coordsToIndex[size][coords] = this.markerCounter[size];

        // Pushes markers to this.markers
        this.markers[size].push(marker);

        // Increments index for next marker (one for each size because tiles render out of order on init)
        this.markerCounter[size]++;

        // Adds appropriate markers to leaflet's ciLayer based on currentZoom
        if (this.currentZoom == size) {
          this.ciLayer.addMarker(marker);
        }
      }

      this.enableMoveTools();

    }


    const clearTile = (snapshot, type) => {
      // Use the coords to get the index of the marker via the markerCoordsToIndex object key
      const coords = snapshot.key.split(':');

      for (let size = 0; size < Object.keys(this.markers).length; size++) {
        // const size = this.sizeKeys[i];
        let coordKey = coords.join(',');
        const coordsToIndex = this.coordsToIndex[size];
        const markerIndex = coordsToIndex[coordKey];

        // Get the marker-to-be-deleted from its array
        let markerToBeRemoved = this.markers[size][markerIndex];

        // Remove the marker
        this.ciLayer.removeMarker(markerToBeRemoved, true);

        // Update global markers object with an empty marker to preserve indices of other markers
        const icon = new L.icon({
          iconUrl: '../../../assets/img/null.png',
          iconSize: [0, 0],
          iconAnchor: [0, 0]
        });

        const marker = L.marker([coords[0], coords[1]], {
          icon: icon,
        });

        this.markers[size][markerIndex] = marker;
      }
    };

    // firebase invokes tile methods
    this.boardRef.on('child_added', drawTile);
    this.boardRef.on('child_changed', clearTile);
    this.boardRef.on('child_changed', drawTile);
    this.boardRef.on('child_removed', clearTile);
  }


  resize() {
    let size = this.currentZoom;
    for (let i = 0; i < this.markers[size].length; i++) {
      for (let j = 0; j < Object.keys(this.markers).length; j++) {
        this.ciLayer.removeMarker(this.markers[j][i]);
      }
    }
    this.ciLayer.addMarkers(this.markers[size]);
    this.ciLayer.redraw();
  }


  disableMoveTools() {
    this.map.dragging.disable();
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
    if (this.map.tap) {
      this.map.tap.disable();
    };
    document.getElementById('map').style.cursor = 'crosshair';
  }


  enableMoveTools() {
    this.map.dragging.enable();
    this.map.boxZoom.enable();
    this.map.touchZoom.enable();
    this.map.scrollWheelZoom.enable();
    if (this.map.tap) {
      this.map.tap.enable();
    };
    document.getElementById('map').style.cursor = 'crosshair';
  }


  bresenhamsLine(x0, y0, x1, y1) {
    // https://en.wikipedia.org/wiki/Bresenham%27s_line_algorithm
    let canvas = document.getElementById('canvas') as HTMLCanvasElement;
    let ctx = canvas.getContext('2d');
    const coordinates = [];
    const dx = Math.abs(x1 - x0);
    const dy = Math.abs(y1 - y0);
    const sx = (x0 < x1) ? 1 : -1;
    const sy = (y0 < y1) ? 1 : -1;
    let err = dx - dy;
    coordinates.push([x0, y0]);
    while (!((x0 === x1) && (y0 === y1))) {

      this.boardRef.child(x0 + ':' + y0).set(this.tile);
      this.tileService.update()

      let e2 = err << 1;
      if (e2 > -dy) {
        err -= dy;
        x0 += sx;
      }
      if (e2 < dx) {
        err += dx;
        y0 += sy;
      }
      coordinates.push([x0, y0]);
    }
    return coordinates;
  }

}
