import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { aFirebaseConfig } from './api-keys';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BoardComponent } from './board/board.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogComponent } from './dialog/dialog.component';
import { EmojiSelectComponent } from './dialog/emoji-select/emoji-select.component';
import { AboutComponent } from './dialog/about/about.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { Emoji } from './utils/emoji';
import { SortablejsModule } from 'ngx-sortablejs';

export const firebaseConfig = {
  apiKey: aFirebaseConfig.apiKey,
  authDomain: aFirebaseConfig.authDomain,
  databaseURL: aFirebaseConfig.databaseURL,
  storageBucket: aFirebaseConfig.storageBucket,
  messagingSenderId: aFirebaseConfig.messagingSenderId
};

@NgModule({
  declarations: [
    AppComponent,
    BoardComponent,
    DialogComponent,
    EmojiSelectComponent,
    AboutComponent
  ],
  entryComponents: [DialogComponent, EmojiSelectComponent, AboutComponent],
  imports: [
    BrowserModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatChipsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    ColorPickerModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    PickerModule,
    SortablejsModule.forRoot({
      animation: 200,
    })
  ],
  providers: [Emoji],
  bootstrap: [AppComponent]
})
export class AppModule { }
