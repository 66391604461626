import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EmojiSearch } from '@ctrl/ngx-emoji-mart';

@Injectable({
  providedIn: 'root'
})
export class TileService {

  last = localStorage.getItem('emoji-mart.last');


  private tile = new BehaviorSubject<string>('');
  params = this.tile.asObservable();

  private palette = new BehaviorSubject<string>('');
  getPalette = this.palette.asObservable();

  // palette = '🦀,🦕,🥦';
  // 🌱,🌲,🥣,❄️,💧,🦕,🐋,🙈,🦀,🦖,🌈,🧀,🍤,🥟,🍞,🐌,🧀,🍤,🥟,🍞,🍐,🥥,🍍,🍑,🥦,🥒,🌶,🥔,🥫,🥘,🌯,🌮,🥙,🍕,🍟,🥩,🍥,🥤
  //😀,😁,😂,🤣,😃,😄,😅,😆,😊,😋,😎,😍,😘,😗,😙,🤢,🤮,🤧,😇,🤠, 🎉,🥌,🌱,💦,🌱,🌲,🥣,❄️,💧,🦕,🌱,💦,🌱,🌲,🥣,❄️,💧,🦕,🐋,🙈,🦀,🦖,🌈,🧀,🍤,🥟,🍞,🐌,🧀,🍤,🥟,🍞,🍐,🥥,🍍,🍑,🥦,🥒,🌶,🥔,🥫,🥘,🌯,🌮,🥙,🍕,🍟,🥩,🍥,🥤🍛,🍣,🍱,🥟,🍤,🍙,🍚,🍘,🍥,🥠,🍢,🍡,🍧,🍨,🍦,🥧,🍰,🎂,🍮,🍭🍬,🍫🍿🧂
  // 🍩,🍪,🌰,🥜,🍯,🥛,🍼,☕️
  counter = 0;
  counterLength = 1;

  constructor(private emojiSearch: EmojiSearch) {}

  init() {
    if ( this.last && this.emojiSearch ) {
      let date = new Date();
      let emoji;
      if ( this.emojiSearch.search(this.last).length > 0 ) {
        emoji = this.emojiSearch.search(this.last)[0].native;
      } else {
        emoji = this.emojiSearch.originalPool[this.last].native;
      }
      let params = emoji + ':' + date.getTime();
      this.tile.next(params);
      this.setPalette(emoji)
    }
  }

  update() {
    let date = new Date();
    let emoji = this.cycle();
    // todo add color
    // let color = this.cycleColor();
    let params = emoji + ':' + date.getTime();
    this.tile.next(params);
  }

  cycle() {
    this.counter++;
    this.counter >= this.palette.value.split(',').length ? this.counter = 0 : null;
    let length = this.palette.value.split(',').length;
    // length-1 instead of conter below
    return this.palette.value.split(',')[this.counter];
  }

  setPalette(palette) {
    this.palette.next(palette);
  }

}
